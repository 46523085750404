<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.van-nav-bar__title,
.van-nav-bar__text,
.van-nav-bar .van-icon {
  color: white !important;
}
span {
  font-weight: bold;
  font-size: 14px;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (e) {
    console.log("1", e.touches.length);
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (e) {
    console.log("2");
    e.preventDefault();
  });
};
</script>
