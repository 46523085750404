import { createRouter, createWebHistory } from "vue-router";
import allGames from "@/views/allGames.vue";

const routes = [
  {
    path: "/",
    component: allGames,
    meta: { title: "ONO 小游戏" },
  },
  {
    path: "/search",
    name: "search",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/search.vue"),
  },
  {
    path: "/search2",
    name: "search2",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/search2.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
