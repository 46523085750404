<template>
  <div class="allGames">
    <van-nav-bar
      title="ONO 小游戏"
      @click-right="onClickRight"
      border
      :left-arrow="false"
    >
    </van-nav-bar>

    <van-tabs v-model="activeName">
      <van-tab name="游戏合集" title="游戏合集">
        <div class="tree-select-container">
          <van-tree-select
            height="100%"
            :items="items"
            @click-nav="clickNav"
            @click-item="clickGame"
            v-model:main-active-index="activeIndex"
          />
        </div>
      </van-tab>
      <van-tab name="教程" title="教程">
        <tutorialVue />
      </van-tab>
      <van-tab name="存档" title="存档">
        <div style="padding: 0 10px">
          <div class="item">
            <p class="title">
              <span class="icon"></span>
              <span>iOS</span>
            </p>
            <p class="index">
              1.点击进入游戏后，点击右上角，使用 Safari 浏览器打开
            </p>
            <p class="index">
              2.点击浏览器下方中间箭头按钮，下拉找到添加到主屏幕即可
            </p>
            <p class="index">3.从桌面打开游戏即可存档</p>
          </div>
          <div class="item">
            <p class="title">
              <span class="icon"></span>
              <span>安卓</span>
            </p>
            <p class="index">1.点击进入游戏后，点击右上角，使用浏览器打开</p>
            <p class="index">2.点击添加到书签</p>
            <p class="index">3.从书签打开即可存档</p>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Button, NavBar, Icon, Tabs, Tab, TreeSelect } from "vant";
import tutorialVue from "./tutorial.vue";
import gameData from "./games2.js";

export default {
  components: {
    tutorialVue,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [TreeSelect.name]: TreeSelect,
  },
  data() {
    return {
      activeIndex: 0,
      activeName: "游戏合集",
      items: [
        {
          text: "收纳类型",
          badge: 15,
          children: [
            {
              text: "收纳物语",
              url: "https://cdn.hanyougame.cn/snwy_web_1.0.1/web-mobile/index.html",
              id: 1,
            },
            {
              text: "收纳达人",
              url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/109/1.0.0/web-mobile/index.html",
              id: 2,
            },
            {
              text: "小小收纳",
              url: "https://z10.tanyu.mobi/tanpaixyx/18002/web/1.0.170/index.html",
              id: 3,
            },
            {
              text: "收纳天才",
              url: "https://updatecdn.chutaonet.cn/collect/web_game/web-mobile/index.html",
              id: 4,
            },
            {
              text: "我的收纳摊",
              url: "https://z10.tanyu.mobi/tanpaixyx/16003/web/1.0.110/index.html",
              id: 5,
            },
            {
              text: "脑洞人爱收纳",
              url: "https://maoyumeng.cdn.xiaojing.work/chdl/cydmx/web/web-mobile1/index.html",
              id: 6,
            },
            {
              text: "喵星人爱收纳",
              url: "https://maoyumeng.cdn.xiaojing.work/chdl/mxrasn/remote/web/web-mobile/index.html",
              id: 7,
            },
            {
              text: "超级收纳馆",
              url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/cjjyg/web/index.html",
              id: 8,
            },
            {
              text: "第亿次收纳",
              url: "https://maoyumeng.club/dycsn/web/web-mobile/index.html",
              id: 9,
            },
            {
              text: "鸭鸭收纳大师",
              url: "https://www.rewanyouxi.com/games/snds/",
              id: 10,
            },
            {
              text: "收纳模拟器",
              url: "http://112.74.33.201:8080/cnmnq",
              id: 11,
            },
            {
              text: "沉浸解压馆",
              url: "https://cooperation.pailedi.com/web2/gameClean/web-mobile23/index.html",
              id: 12,
            },
            {
              text: "樱花校园收纳",
              url: "https://game.k54k.cn/gamefile/ttd68da03049b2f17c02/web-mobile/",
              id: 13,
            },
            {
              text: "鼠鼠收纳",
              url: "https://trident.icu/web-mobile/index.html",
              id: 14,
            },
            {
              text: "收纳人生",
              url: "https://www.lekonghd.cn/newsndrs/3.1.6/",
              id: 15,
            },
          ],
        },
        {
          text: "烹饪游戏",
          badge: 5,
          children: [
            {
              text: "旅行餐车",
              url: "https://qcdn.bdoggame.com/ttgame/lvxingcc/webVer/index.html",
              id: 1,
            },
            {
              text: "开了个西餐厅",
              url: "https://game.csquyou.com/mhxcd/",
              id: 2,
            },
            {
              text: "夜市餐厅",
              url: "https://game2.sfplay.net/nightMarket/v1",
              id: 3,
            },
            {
              text: "武林大厨",
              url: "https://file.gugudang.com/res/down/public/p_myhotel/web-mobile/lts/index.html",
              id: 4,
            },
            {
              text: "喵喵小厨娘",
              url: "https://enjoygaga-1300513096.cos.ap-shanghai.myqcloud.com/game/cooking/index.html",
              id: 5,
            },
            {
              text: "我是大玩家绿皮车",
              url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/119/ttzhise/web-mobile/index.html",
              id: 6,
            },
          ],
        },
      ],
      categoryMap: {
        6: "模拟经营",
        7: "角色扮演",
        8: "打螺丝类",
        9: "烧脑爆梗",
        10: "收纳类型2",
        11: "休闲益智",
        12: "冒险解谜",
        13: "策略修仙",
        14: "战略塔防",
      },
    };
  },
  mounted() {
    this.loadGames();
    this.items.push(
      {
        text: "更多游戏",
        dot: true,
      },
      {
        text: "更多游戏(热度排行)",
        dot: true,
      }
    );
  },
  methods: {
    loadGames() {
      const categoriesMap = new Map(
        this.items.map((item) => [item.text, item])
      );

      gameData.forEach((game) => {
        const categoryId = game.category_id;
        const categoryName = this.categoryMap[categoryId] || "其他";

        if (!categoriesMap.has(categoryName)) {
          categoriesMap.set(categoryName, {
            text: categoryName,
            children: [],
          });
        }

        const category = categoriesMap.get(categoryName);
        category.children.push({
          text: game.name + (game.remok ? '【' + game.remok + '】' : ''),
          url: game.url,
          id: category.children.length + 1,
        });
      });

      this.items = this.items.map(
        (item) => categoriesMap.get(item.text) || item
      );
      categoriesMap.forEach((value, key) => {
        if (!this.items.some((item) => item.text === key)) {
          this.items.push(value);
        }
      });

      this.items.forEach((item) => {
        item.badge = item.children ? item.children.length : 0;
      });
    },
    clickGame(data) {
      window.open(data.url);
    },
    onClickRight() {
      this.$router.push("/search");
    },
    clickNav(index) {
      this.activeIndex = index;
      const lastIndex = this.items.length - 2;
      const secondLastIndex = this.items.length - 1;

      if (index === lastIndex) {
        this.$router.push("/search");
      } else if (index === secondLastIndex) {
        this.$router.push("/search2");
      }
    },
  },
};
</script>

<style lang="less">
.allGames {
  .van-nav-bar {
    background: hotpink;
    padding-top: env(safe-area-inset-top);
    height: calc(44px + env(safe-area-inset-top));
    box-sizing: border-box;
  }
  .van-sidebar {
    background: #e8e8e8;
  }
  .tree-select-container {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .item {
    margin-top: 10px;
    box-shadow: 0 0 8px #00000048;
    padding: 10px;
    .title {
      margin: 0;
      display: flex;
      align-items: center;
      font-weight: 800;
      .icon {
        display: inline-block;
        width: 5px;
        height: 25px;
        background: pink;
        margin-right: 10px;
      }
    }
    .index {
      text-align: left;
      font-size: 14px;
      margin: 8px 0 8px 14px;
    }
  }
}
</style>
